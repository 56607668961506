
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mdiMagnify } from "@mdi/js";

@Component
export default class SearchInput extends Vue {
    @Prop({ required: false, default: "", type: String }) defaultInput!: string;
    searchIcon: string = mdiMagnify;
    query = this.defaultInput;
    debounce: unknown = null;

    @Watch("defaultInput")
    onInputChange(input: string) {
        this.query = input;
    }

    debounceChange() {
        if (this.debounce !== null) {
            clearTimeout(this.debounce as number);
        }
        this.debounce = setTimeout(() => {
            this.$emit("change", this.query);
        }, 600);
    }
}
